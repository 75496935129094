<template>
  <validation-observer ref="form" v-slot="{ invalid }">
    <layout width="420">
      <template slot="header">
        Create object group
      </template>

      <template>
        <v-form>
          <validation-provider
            v-slot="{ errors }"
            name="Property name"
            rules="required"
          >
            <v-text-field
              v-model.trim="state.name"
              label="Name"
              :error-messages="errors"
            />
          </validation-provider>
          <edit-rights-group-fields
            v-model="accessRights"
            :rights-editor="rightsEditor"
            :rights-user="rightsUser"
            :rights-reader="rightsReader"
          />
          <form-subheader title="Description" />
          <v-textarea
            v-model="state.description"
            no-resize
            rows="1"
            auto-grow
            hide-details
          />
        </v-form>
      </template>
      <template slot="footer">
        <v-spacer />
        <v-btn text color="text-primary" @click.stop="$emit('close')">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          depressed
          :disabled="invalid"
          :loading="loading"
          @click.stop="exec"
        >
          Add
        </v-btn>
      </template>
    </layout>
  </validation-observer>
</template>
<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { onMounted, ref } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import ObjectGroupService from '../api/object-group.js';
import EditRightsGroupFields from '@/modules/access/ui/EditRightsGroupFields.vue';
import { useAccessRights } from '@/modules/access/compositions/access-rights';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: 'ObjectGroupCreate',
  components: {
    Layout,
    EditRightsGroupFields,
    ValidationObserver,
    ValidationProvider
  },
  setup(props, { emit }) {
    const form = ref(null);
    const state = ref({
      name: '',
      descriprion: '',
      schemaId: ''
    });

    const accessRights = ref({
      editorgroup: undefined,
      usergroup: undefined,
      readergroup: undefined
    });

    const {
      load,
      rightsEditor,
      rightsUser,
      rightsReader,
      save
    } = useAccessRights();

    const submit = async () => {
      const res = await ObjectGroupService.create(state.value);
      const groupId = res?.data?.createObject?.object?.id;
      await save(groupId, accessRights.value);
      emit('close');
    };

    const { loading, exec } = usePromise(submit, true);

    onMounted(async () => {
      load();
      state.value.schemaId = await ObjectGroupService.fetchObjectGroupSchemeId();
    });

    return {
      state,
      exec,
      loading,
      rightsEditor,
      rightsUser,
      rightsReader,
      accessRights,
      form
    };
  }
};
</script>
